.BasicsItems {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .BasicsItems {
    flex-direction: column;
  }
}
