.Process {
  background-color: var(--color-gray-light);
  transform: skew(0deg, 3deg);
  padding-bottom: 5rem;
}

.Process__Content {
  max-width: 800px;
  padding-top: 4rem;
  transform: skew(0deg, -3deg);
}

.Process__ImageBox {
  display: flex;
  justify-content: center;
}

.Process__Image {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 800px) {
  .Process {
    padding-bottom: 2rem;
  }

  .Process__Content {
    padding-top: 3rem;
  }
}
