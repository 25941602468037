.Overview {
  background-color: var(--color-blue);
  padding-top: 4rem;
  transform: skew(0deg, 3deg);
  padding-bottom: 6rem;
}

.Overview__Content {
  color: var(--color-white);
  max-width: 800px;
  margin: 0 auto;
  transform: skew(0deg, -3deg);
}

.Overview__Title {
  margin-bottom: 1rem;
}

.Overview__SubTitle {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 800px) {
  .Overview {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .Overview__Content {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
