a,
a.link {
  line-height: 24px;
  letter-spacing: -0.1px;
  font-size: 16px;
  position: relative;
  transition: border-bottom 200ms, color 200ms;
  outline: none;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-blue);
}

a:hover,
a:focus {
  border-bottom: 1px solid var(--color-blue-active);
  color: var(--color-blue-active);
}
