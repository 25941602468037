.Research {
  padding-bottom: 5rem;
}

.Research__Content {
  max-width: 800px;
  padding-top: 5rem;
}

.Research__Title {
  margin-bottom: 1rem;
}

.Research__SubTitle {
  white-space: nowrap;
  overflow: visible;
}

.Research__Box {
  display: flex;
  justify-content: space-between;
}

.Research__Text {
  margin-right: 1rem;
  max-width: 400px;
}

.Research__Image {
  width: 100%;
  max-width: 400px;
  height: auto;
  border: 1px solid var(--color-black);
}

.Research__PainPoints {
  margin-top: 0.5rem;
}

@media only screen and (max-width: 800px) {
  .Research {
    padding-bottom: 3rem;
  }

  .Research__Content {
    padding-top: 2rem;
  }

  .Research__Box {
    flex-direction: column-reverse;
  }

  .Research__Image {
    margin-bottom: 1rem;
  }

  .Research__Image {
    max-width: 100%;
  }

  .Research__Text {
    max-width: 100%;
  }
}
