.About {
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.About__Content {
  margin-top: -20%;
  width: 60%;
}

.About__Hero {
  color: var(--color-blue);
  margin-left: -0.05em;
  font-weight: 800;
  font-size: 96px;
  letter-spacing: -3.5px;
}

.About__Intro {
  font-weight: 800;
  line-height: 1.15;
}

.About__Text {
  margin-top: 2rem;
}

@media only screen and (max-width: 768px) {
  .About__Content {
    width: 70%;
  }

  .About__Hero {
    font-size: 72px;
    letter-spacing: -3px;
  }

  .About__Intro {
    font-weight: 800;
    line-height: 1.05;
  }
}
