.BasicsTools {
  margin-top: 4rem;
}

.BasicsTools__Title {
  text-align: center;
  margin-bottom: 1rem;
}

.BasicsTools__Items {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .BasicsTools {
    margin-top: 0;
  }
}
