.Content {
  max-width: 960px;
  margin: 0 auto;
}

@media only screen and (max-width: 960px) {
  .Content {
    margin: 0 1rem;
  }
}
