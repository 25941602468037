.BasicsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.3333%
}

@media only screen and (max-width: 768px) {
  .BasicsItem {
    width: 100%;
    margin-bottom: 2rem;
  }
}