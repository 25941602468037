.LogoLink {
  background: none;
  border: none;
  padding: 0;
}

.LogoLink:hover {
  border-bottom: none;
  cursor: pointer;
}

.LogoLink:focus {
  outline: none;
  border-bottom: none;
}
