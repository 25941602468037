:root {
  /* General colors */
  --color-black: #0e0f0f;
  --color-white: #fff;

  /* Core colors */
  --color-gray-light: #f0f0f0;
  --color-gray: #979b9e;
  --color-blue-active: #1c69bb;
  --color-blue: #2280e4;
}
