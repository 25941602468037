.Header {
  height: 80px;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.Header__Menu {
  margin-top: 14px;
  margin-right: 5px;
}
