.Button {
  color: var(--color-white);
  font-size: 14px;
  letter-spacing: 0.1px;
  width: 200px;
  padding: 8px 16px;
  border: 1px solid var(--color-black);
  background-color: var(--color-blue);
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  display: inline-block;
  transform: skew(-10deg);
  transition: 0.5s;
  outline: none;
}

.Button:after {
  content: '';
  width: 0px;
  height: 120%;
  position: absolute;
  left: -10%;
  bottom: 0;
  background-color: var(--color-blue-active);
  transform: skew(-10deg);
  z-index: -1;
  transition: 0.3s;
}

.Button:hover {
  cursor: pointer;
}

.Button:hover:after {
  left: -10%;
  width: 120%;
}

.Button > span {
  display: inline-block;
  transform: skew(10deg);
}
