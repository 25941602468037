.RoomIntro {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  align-items: center;
}

.RoomIntro__Heading {
  text-align: center;
}

.RoomIntro__Image {
  margin-top: 2rem;
  width: 100%;
  max-width: 800px;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .RoomIntro {
    margin-bottom: 1rem;
  }

  .RoomIntro__Heading--last {
    margin-top: 1rem;
  }
}
