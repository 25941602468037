p {
  font-variant-ligatures: none;
  line-height: 1.5;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.caption {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}

h1 {
  font-size: 48px;
  letter-spacing: -0.5;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
  font-weight: 600
}

.caption {
  font-size: 11px;
  line-height: 16px;
  color: var(--color-gray);
  text-transform: uppercase;
  letter-spacing: 0.4px;
}