.Solution {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Solution__Heading {
  text-align: center;
}

.Solution__Image {
  width: 100%;
  max-width: 800px;
  height: auto;
}
