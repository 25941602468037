.Basics {
  background-color: var(--color-gray-light);
  padding-top: 5rem;
  min-height: 368px;
}

@media only screen and (max-width: 768px) {
  .Basics {
    padding-top: 2rem;
  }
}
