.MenuLink {
  color: var(--color-black);
  text-decoration: none;
}

a:hover,
a:focus {
  border-bottom: 1px solid var(--color-blue-active);
  color: var(--color-black);
}

.MenuLink::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: var(--color-black);
  transition: width 0.2s;
}

.MenuLink--active::after {
  width: 70%;
  background: var(--color-blue-active);
}

.MenuLink:hover::after {
  width: 70%;
}
