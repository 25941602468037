.WorkTile {
  display: flex;
  justify-content: space-between;
}

.WorkTile__Content {
  margin-top: 5rem;
  margin-right: 1rem;
}

.WorkTile__Intro {
  display: flex;
  align-items: center;
}

.WorkTileIntro__Divider {
  margin: -0.5rem 1rem 0;
  height: 1rem;
  border-right: 1px solid var(--color-gray);
}

.WorkTile__Button {
  margin-top: 1rem;
  display: flex;
}

.WorkTile__Image {
  width: 100%;
  max-width: 400px;
  height: auto;
  border: 1px solid var(--color-gray-light);
}

.WorkTile_ImageLink {
  background: none;
  border: none;
  padding: 0;
}

.WorkTile_ImageLink:focus {
  outline: none;
}

.WorkTile_ImageLink:hover {
  border-bottom: none;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .WorkTile {
    flex-direction: column;
  }
  .WorkTile__Content {
    margin: 1rem 0;
  }
  .WorkTile__Image {
    max-width: 100%;
  }
}
