.BasicsTool {
  width: 160px;
  height: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.BasicsTool__Image {
  width: auto;
  height: 100%;
}

.BasicsTool__Title {
  align-items: center;
  font-size: 11px;
  text-transform: uppercase;
}

.BasicsTool--VSCode,
.BasicsTool--React {
  margin-top: 3px;
  height: 64px;
}

.BasicsTool--Sketch {
  height: 70px;
}

@media only screen and (max-width: 768px) {
  .BasicsTool__Title {
    display: none;
  }
}
